<header>
  <div class="primary-container">
    <div class="primary" [class.center]="communitySignal() === undefined">
      <app-logo class="logo" />

      @if ( communitySignal(); as community ) {
        <div class="user-container">
          @if ( !currentUserStateSignal().pending ) {
            @if ( currentUserStateSignal().result; as user ) {
              @if ( user.firstName !== undefined && user.lastName !== undefined ) {
                <div class="user" [title]="user.email">{{ user.firstName }} {{ user.lastName }}</div>
              } @else {
                <div class="user">{{ user.email }}</div>
              }

              <div class="user-actions">
                <a [routerLink]="[ '/communities', community.slug, 'profile' ]">Profile</a>
                <a [routerLink]="[ '/communities', community.slug, 'sign-out' ]">Sign Out</a>
              </div>
            } @else {
              <div class="user-actions">
                <a [routerLink]="[ '/communities', community.slug, 'sign-in' ]">Sign In / Register</a>
              </div>
            }
          } @else {
            <app-loading small light />
          }
        </div>
      }
    </div>
  </div>

  @if ( communitySignal(); as community ) {
    <div class="secondary-container">
      <div class="secondary">
        <div class="community-name">{{ community.name }}</div>
      </div>
    </div>
  }
</header>

<div class="main-container">
  <main>
    @if ( !navigatingSignal() ) {
      <router-outlet />
    } @else {
      <app-loading class="loading" />
    }
  </main>
</div>
