import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { MainComponent } from './main.component';
import { CommunityStateService } from '../services/state/community-state.service';
import { NavigationStateService } from '../services/state/navigation-state.service';
import { UserService } from '../services/user.service';

@Component( {
  selector: 'app-main-controller',
  template: `
    <app-main
      [navigating]="navigatingSignal()"
      [currentUserState]="currentUserStateSignal()"
      [community]="communitySignal()"
    />
  `,
  styles: `:host { display: contents; }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ MainComponent ],
} )
export class MainControllerComponent {
  private readonly navigationStateService = inject( NavigationStateService );
  private readonly userService = inject( UserService );
  private readonly communityStateService = inject( CommunityStateService );

  protected readonly navigatingSignal = toSignal( this.navigationStateService.navigatingObservable, { initialValue: false } );
  protected readonly currentUserStateSignal = toSignal( this.userService.currentUserStateObservable, { requireSync: true } );
  protected readonly communitySignal = toSignal( this.communityStateService.currentCommunityObservable, { requireSync: true } );
}
