import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import type { RouterStateSnapshot } from '@angular/router';
import { TitleStrategy } from '@angular/router';

import type { Community } from 'prayer-rotation-base';

import { getDeepestActivatedRouteSnapshot } from '../utilities/get-deepest-activated-route-snapshot';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  private static readonly appName = 'Prayer Rotation';

  private readonly title = inject( Title );

  override updateTitle( snapshot: RouterStateSnapshot ): void {
    const community = getDeepestActivatedRouteSnapshot( snapshot.root ).data[ 'community' ] as Community | undefined;

    const pageTitle = this.buildTitle( snapshot );
    const title = `${ pageTitle !== undefined ? `${ pageTitle } - ` : '' }${ community !== undefined ? `${ community.name } | ` : '' }${ AppTitleStrategy.appName }`;

    this.title.setTitle( title );
  }
}
