import type { Route } from '@angular/router';

export const mainRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: async () => ( await import( './home/home-controller.component' ) ).HomeControllerComponent,
  },
  {
    path: 'communities',
    loadChildren: async () => ( await import( './communities/communities.routes' ) ).communitiesRoutes,
  },
  {
    path: 'error',
    loadComponent: async () => ( await import( './error/error-controller.component' ) ).ErrorControllerComponent,
  },
  {
    path: '**',
    loadComponent: async () => ( await import( './error/error-controller.component' ) ).ErrorControllerComponent,
  },
];
