import { inject, Injectable } from '@angular/core';
import type { Event } from '@angular/router';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable( { providedIn: 'root' } )
export class NavigationStateService {
  private readonly router = inject( Router );

  readonly navigatingObservable = this.router.events
    .pipe( filter( isNavigationEvent ) )
    .pipe( map( isNavigationStartEvent ) );
}

function isNavigationEvent( event: Event ): event is NavigationStart | NavigationEnd | NavigationError | NavigationCancel {
  return (
    event instanceof NavigationStart ||
    event instanceof NavigationEnd ||
    event instanceof NavigationError ||
    event instanceof NavigationCancel
  );
}

function isNavigationStartEvent( event: Event ): event is NavigationStart {
  return event instanceof NavigationStart;
}
