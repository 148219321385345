import { provideHttpClient, withInterceptors } from '@angular/common/http';
import type { ApplicationConfig } from '@angular/core';
import { provideExperimentalZonelessChangeDetection } from '@angular/core';
import { PreloadAllModules, provideRouter, TitleStrategy, withPreloading, withRouterConfig } from '@angular/router';

import { mainRoutes } from './main.routes';
import { environment } from '../environments/environment';
import { EnvironmentInjectionToken } from '../models/environment';
import { withCredentialsInterceptor } from '../services/api/with-credentials.interceptor';
import { AppTitleStrategy } from '../services/title-strategy';

export const mainConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(
      mainRoutes,
      withPreloading( PreloadAllModules ),
      withRouterConfig( { paramsInheritanceStrategy: 'always' } ),
    ),
    provideHttpClient( withInterceptors( [ withCredentialsInterceptor ] ) ),
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: EnvironmentInjectionToken, useValue: environment },
  ],
};
